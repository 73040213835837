import React, { useUnit } from 'effector-react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import {
  $open,
  changeAPI,
  currentApi,
  onSetOpenAlert,
} from '../../model/currentApi';

export default function AlertApi() {
  const open = useUnit($open);

  const handleClose = () => {
    onSetOpenAlert(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Текущее api {currentApi.value}</DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            changeAPI(process.env.REACT_APP_API_DEV ?? '');
            onSetOpenAlert(false);
            window.location.reload();
          }}
        >
          Set dev API
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            changeAPI(process.env.REACT_APP_API_PROD ?? '');
            onSetOpenAlert(false);
            window.location.reload();
          }}
        >
          Set prod API
        </Button>
      </DialogActions>
    </Dialog>
  );
}
